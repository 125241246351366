.topNavbarContainer {
  padding         : 8px;
  background-color: #F6F2F2;
  width           : 85%;
  margin          : auto;
  margin-top      : 15px;
  height          : 60px;
  border-radius   : 15px;
  margin-left     : 10px;

.myPlansContainer {
  width: 90%;
  margin: auto;
  margin-top: 60px;
}

  .navbarLink {
    font-weight    : 400;
    font-size      : 20px;
    margin-right   : 40px;
    text-decoration: none;
    padding-bottom : 12px;
    color          : #000000;
    font-family    : "Inter", sans-serif;
    &.active {
      border-bottom: 5px solid #CCA6F9;
    }
    &:hover {
      text-decoration: none;
    }

  }

  .accountText {
    font-size   : 20px;
    margin-right: 50px;
    cursor      : pointer;
    &:hover{
      text-decoration: none;

    }
  }
}

.myPlansContainer {
  width     : 90%;
  margin    : auto;
  margin-top: 60px;

  .box {
    background-color: #d6b6ff;
    border-radius   : 30px;
    padding         : 4px;
    margin-bottom   : 4px;
    position        : relative;
    width           : 450px;
    height          : 450px;

    .icon {
      position: absolute;
      top     : -50px;
      right   : -45px;
      color   : #D99BFF;
      height  : 80px;
    }
  }
}

.wrapper {
  width     : 95%;
  margin    : auto;
  margin-top: 10px;
}

.inputDiv {
  background-color: #F6F2F2 !important;
  margin-top      : 15px;
  display         : flex;
}

.grid {
  margin-top : 5vh;
  margin     : 5vw;
  margin-left: 5vw;
}

.datePicker {
  background-color: #F6F2F2 !important;
  margin-top      : 15px;
  outline         : 2px solid transparent;
  outline-offset  : 2px;
  padding         : 10px 16px;
  border-radius   : 0.375rem;
  width           : 100%;
}

.inputIconsDiv {
  display       : flex;
  align-items   : end;
  flex-direction: column;
  margin-top    : 15px;
  margin-right  : 10px;
}

.datePickerWrapper {
  display       : flex;
  flex-direction: column;
  width         : 100%;
}

.createTripButton {
  display        : flex;
  justify-content: center;
}

.createTrip {
  background-color: #CCA6F9 !important;
  font-family     : 'Inter';
  font-size       : 24px;
  font-weight     : 400;
  line-height     : 54px;
  letter-spacing  : 0em;
}

.container {
  margin-top: 40px !important;
  width     : 90%;
  margin    : 0 auto;
}

.tagsWrapper {
  display    : flex;
  align-items: center;
  gap        : 20px;
}

.topConatiner {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.stepOneHeader {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.chevron-counter {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  width          : 200px;

}

.chevron-icon {
  cursor   : pointer;
  font-size: 50px;
  color    : #D9D9D9 !important;
  transform: rotate(270deg);
}

.count {
  font-size    : 18px;
  font-weight  : bold;
  color        : #333;
  border-bottom: 1px solid #000000;
}

.tag {
  background   : #CCA6F9 !important;
  padding      : 5px 25px !important;
  height       : 34px;
  border-radius: 25px !important;
  font-weight  : bold;

}

.stepTwoCard {
  width           : 400px;
  height          : 600px;
  background-color: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;


}

.stepThreeCard {
  width           : 1000px;
  height          : 500px;
  background-color: #D9D9D9;
  display         : flex;
  justify-content : end;
  align-items     : end;
  gap             : 30px;
  padding         : 30px;


}
.modalWrapper{
  width: 400px;
  border-radius: 10px;
  background: #F6F2F2;
  padding: 40px 30px;
  margin: auto;
  margin-bottom: 20px;
}
.inputField{
  background-color: #FEFEFE !important;
  border-radius: 10px;
  margin: 10px 0;
&::placeholder{
  color:#000000;
}
}
.saveDeatilsButton{
  background-color: #B780FCC2 !important;
  padding: 10px 50px !important;
  border-radius: 15px !important;
  margin-top: 40px;
  // margin: 40px auto;
  &:hover{
    background-color: #B780FCC2 !important; 
  }
}