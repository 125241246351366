.flashcard-container {
    text-align: center;
    transform-origin: bottom left;
}

.flashcard-container h1 {
    font-size: 24px; /* Adjust the font size as needed */
    font-weight: bold;
    margin-top: 10px; /* Add some top margin for spacing */
}

.flashcard-container h2 {
    font-size: 20px; /* Adjust the font size as needed */
    font-weight: bold;
    margin-top: 8px; /* Add some top margin for spacing */
}
